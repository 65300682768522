@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
}

.greychat { 
  @apply bg-slate-200 rounded-xl px-3 py-1 w-11/12 mr-auto mt-2 mb-2;
}

.bluechat {
  @apply bg-indigo-600 rounded-xl px-3 py-1 w-11/12 text-white ml-auto mt-2 mb-2;
}

.redchat {
  @apply bg-red-600 rounded-xl px-3 py-1 w-11/12 text-white ml-auto mt-2 mb-2;
}

.greenchat {
  @apply bg-green-600 rounded-xl px-3 py-1 w-11/12 text-white ml-auto mt-2 mb-2;
}

.pagination {
  @apply z-0 inline-flex shadow-sm -space-x-px mt-10 mb-10;
}

.navitem {
  @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}

.navitem-active {
  @apply z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}

.navitem-disabled {
  @apply bg-gray-200 border-gray-300 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}